import {Controller} from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";
import searchEngine from "../autocomplete/search_highlighter";
import sourceFactory from "../autocomplete/source_factory";
import * as DOMPurify from 'dompurify';
import i18n from "../i18n";

const FORMATTED_VALUE_ALLOWED_TAGS = ['b', 'i', 'u', 'strong', 'em'];

// Connects to data-controller="autocomplete-select"
export default class extends Controller {
    static targets = ['searchInput', 'searchWrapper', 'valueInput', 'formattedValue', 'formattedValueWrapper']
    static values = {
        placeholder: String,
        suggestions: Array,
        sourceUrl: String,
        sourceType: String,
        sourceParams: String,
        sourceOptions: Object,
        maxResults: {type: Number, default: 50},
        debounce: {type: Number, default: 300}
    }

    connect() {
        this._validate();
        this._initialize();
    }

    disconnect() {
        super.disconnect();
        if (this.autoComplete) {
            this.autoComplete.close();
            this.autoComplete.unInit();
        }
    }

    _validate() {
        let errors = [];
        if (!this.hasSearchInputTarget) {
            errors.push('Search input target must be present (data-autocomplete-select-target="searchInput")')
        }
        if (!this.hasValueInputTarget) {
            errors.push('Selected value target must be present (data-autocomplete-select-target="valueInput")')
        }
        let sourceCount = (this.hasSuggestionsValue ? 1 : 0) +
            (this.hasSourceTypeValue ? 1 : 0) +
            (this.hasSourceUrlValue ? 1 : 0);
        if (sourceCount === 0) {
            errors.push('Either one of sourceTypeValue, sourceUrlValue, or suggestionsValue must be specified')
        } else if (sourceCount > 1) {
            errors.push('Only one of sourceTypeValue, sourceUrlValue, or suggestionsValue must be specified')
        }
        if (errors.length) {
            throw `Invalid configuration: ${errors.join(', ')}`
        }
    }

    _initialize() {
        let data, config, source, autocompletion, extraParams;
        if (this.hasSourceParamsValue && this.sourceParamsValue) {
            if (/^{.*}$/.test(this.sourceParamsValue).matches) {
                extraParams = new URLSearchParams(JSON.parse(this.sourceParamsValue)).toString();
            } else {
                extraParams = this.sourceParamsValue;
            }
        }
        if (this.hasSourceTypeValue) {
            const options = {};
            if (extraParams && extraParams.length) {
                options['extraParams'] = extraParams;
            }
            if (this.hasSourceOptionsValue) {
                Object.assign(options, this.sourceOptionsValue);
            }
            source = sourceFactory.create(this.sourceTypeValue, options);
            data = {
                src: async (query) => {
                    try {
                        return await source.fetch(query);
                    } catch (error) {
                        console.error(error);
                        return error;
                    }
                },
                keys: source.keys
            }
        } else if (this.hasSourceUrlValue) {
            data = {
                src: async (query) => {
                    let url = this.sourceUrlValue;
                    if (this.sourceUrlHandler) {
                        url = this.sourceUrlHandler(url);
                        if (!url) {
                            throw 'sourceUrlHandler did not return a URL';
                        }
                    } else if (url.includes('--query')) {
                        url = url.replace('--query', encodeURIComponent(query));
                    } else {
                        url = `${url}${url.includes('?') ? '&' : '?'}q=${encodeURIComponent(query)}`;
                    }
                    if (extraParams) {
                        url += '&' + extraParams;
                    }
                    try {
                        const response = await fetch(url);
                        return await response.json();
                    } catch (error) {
                        console.error(error);
                        return error;
                    }
                },
            }
        } else {
            data = {
                src: this.suggestionsValue
            };
        }
        config = {
            selector: () => this.searchInputTarget, data: data,
            debounce: this.debounceValue,
            searchEngine: searchEngine,
            resultsList: {
                element: (list, data) => {
                    const info = document.createElement("p");
                    info.classList.add('text-center', 'small', 'm-1');
                    if (data.results.length === 0) {
                        info.innerHTML = i18n.t('js.autocomplete.results_header.none', {
                            query: `<strong>${i18n.t('quoted_value', {value: data.query})}</strong>`
                        });
                    } else if (data.results.length !== data.matches.length) {
                        info.innerHTML = i18n.t('js.autocomplete.results_header.partial', {
                            number_shown: `<strong>${data.results.length}</strong>`,
                            total_size: `<strong>${data.matches.length}</strong>`
                        });
                    } else {
                        info.innerHTML = i18n.t('js.autocomplete.results_header.all', {
                            count: data.results.length,
                            total_size: `<strong>${data.matches.length}</strong>`
                        });
                    }
                    list.prepend(info);
                },
                noResults: true,
                maxResults: this.maxResultsValue
            },
            resultItem: {
                element: source ? source.styleOptionNode : null,
                highlight: true
            },
            events: {
                input: {
                    focus() {
                        const inputValue = autocompletion.input.value;
                        if (inputValue.length) autocompletion.start();
                    },
                    blur: () => {
                        autocompletion.close();
                        if (this.valueInputTarget.value) {
                            this._showComponent('formattedValue');
                        }
                    },
                    open() {
                        const canFitBelow =
                            autocompletion.input.getBoundingClientRect().bottom + autocompletion.list.getBoundingClientRect().height <=
                            (window.innerHeight || document.documentElement.clientHeight);
                        autocompletion.list.classList.toggle('position-above', !canFitBelow);
                    },
                },
            }
        };

        this.searchInputTarget.addEventListener("selection", (e) => {
            if (source && source.onOptionSelected) {
                source.onOptionSelected(e.detail.selection, this.valueInputTarget);
            }
            this._setSelectedValue(e.detail.selection.value, source);
            this._showSelection(e.detail.selection, source);
            // Reset search field
            e.target.value = null;
        });

        // Importing {autoComplete as AutoComplete} results in an error
        // noinspection JSPotentiallyInvalidConstructorUsage
        autocompletion = new autoComplete(config);
        this.autoComplete = autocompletion;
    }

    clearValue(focus = true) {
        this._setSelectedValue(null);
        this._showSelection(null);
        if (focus) {
            this.searchInputTarget.focus();
        }
    }

    editValue(focus = true) {
        this._showComponent('search');
        if (focus) {
            this.searchInputTarget.focus();
        }
    }

    _showComponent(name) {
        if (!["search", "formattedValue"].includes(name)) {
            throw 'Name must be one of: "search", or "formattedValue"'
        } else {
            this.formattedValueWrapperTarget.classList.toggle('d-none', name !== "formattedValue");
            this.searchWrapperTarget.classList.toggle('d-none', name !== "search");
        }
    }

    _setSelectedValue(selectedOption, source) {
        const optionId = selectedOption ? (source && source.getOptionId ? source.getOptionId(selectedOption) : selectedOption.id) : null;
        this.valueInputTarget.value = optionId || "";
        this.valueInputTarget.dispatchEvent(new Event('change', {'bubbles': true}));
    }

    _showSelection(selection, source) {
        if (this.hasSearchWrapperTarget && this.hasFormattedValueTarget && this.hasFormattedValueWrapperTarget) {
            if (selection) {
                if (this.formattedValueTarget.tagName === 'INPUT') {
                    this.formattedValueTarget.value = DOMPurify.sanitize(selection.match, {ALLOWED_TAGS: []});
                } else {
                    this.formattedValueTarget.innerHTML = DOMPurify.sanitize(selection.match, {ALLOWED_TAGS: FORMATTED_VALUE_ALLOWED_TAGS});
                }
                if (source) {
                    source.styleFormattedValue(this.formattedValueTarget, selection);
                }
                this._showComponent('formattedValue')
            } else {
                this.formattedValueTarget.innerHTML = null;
                this._showComponent('search')
            }
        }
    }
}
