import {Controller} from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js/dist/autoComplete";
import searchEngine from "../autocomplete/search_highlighter";
import sourceFactory from "../autocomplete/source_factory";

// Connects to data-controller="simple-autocomplete"
export default class extends Controller {
    static targets = ['input']
    static outlets = ['list-group']
    static values = {
        placeholder: String,
        suggestions: Array,
        sourceUrl: String,
        sourceKey: {type: String, default: 'name'},
        sourceType: String,
        sourceParams: String,
        sourceOptions: Object,
        maxResults: {type: Number, default: 50},
        debounce: {type: Number, default: 300}
    }

    connect() {
        this._validate();
        this._initialize();
    }

    disconnect() {
        super.disconnect();
        if (this.autoComplete) {
            this.autoComplete.close();
            this.autoComplete.unInit();
        }
    }

    _validate() {
        let errors = [];
        if (!this.hasInputTarget) {
            errors.push('Input target must be present (data-autocomplete-select-target="input")')
        }
        if (this.hasInputTarget && this.inputTarget === this.element) {
            // If you register the controller on the input tag, stimulus will end op doing an infinite
            // connect/disconnect loop because the element will be replaced and you're gonna have a bad time.
            errors.push('You must register the controller on a wrapper tag')
        }
        let sourceCount = (this.hasSuggestionsValue ? 1 : 0) +
            (this.hasSourceTypeValue ? 1 : 0) +
            (this.hasSourceUrlValue ? 1 : 0);
        if (sourceCount === 0) {
            errors.push('Either one of sourceTypeValue, sourceUrlValue, or suggestionsValue must be specified')
        } else if (sourceCount > 1) {
            errors.push('Only one of sourceTypeValue, sourceUrlValue, or suggestionsValue must be specified')
        }
        if (errors.length) {
            throw `Invalid configuration: ${errors.join(', ')}`
        }
    }

    _initialize() {
        let data, config, source, autocompletion, extraParams;
        if (this.hasSourceParamsValue && this.sourceParamsValue) {
            if (/^{.*}$/.test(this.sourceParamsValue).matches) {
                extraParams = new URLSearchParams(JSON.parse(this.sourceParamsValue)).toString();
            } else {
                extraParams = this.sourceParamsValue;
            }
        }
        if (this.hasSourceTypeValue) {
            const options = {};
            if (extraParams && extraParams.length) {
                options['extraParams'] = extraParams;
            }
            if (this.hasSourceOptionsValue) {
                Object.assign(options, this.sourceOptionsValue);
            }
            source = sourceFactory.create(this.sourceTypeValue, options);
            data = {
                src: async (query) => {
                    try {
                        return await source.fetch(query);
                    } catch (error) {
                        console.error(error);
                        return error;
                    }
                },
                keys: source.keys
            }
        } else if (this.hasSourceUrlValue) {
            data = {
                src: async (query) => {
                    let url = this.sourceUrlValue;
                    if (this.sourceUrlHandler) {
                        url = this.sourceUrlHandler(url);
                        if (!url) {
                            throw 'sourceUrlHandler did not return a URL';
                        }
                    } else if (url.includes('--query')) {
                        url = url.replace('--query', encodeURIComponent(query));
                    } else {
                        url = `${url}${url.includes('?') ? '&' : '?'}q=${encodeURIComponent(query)}`;
                    }
                    if (extraParams) {
                        url += '&' + extraParams;
                    }
                    try {
                        const response = await fetch(url);
                        return await response.json();
                    } catch (error) {
                        console.error(error);
                        return error;
                    }
                },
                keys: [this.sourceKeyValue],
            }
        } else {
            data = {
                src: this.suggestionsValue
            };
        }
        config = {
            selector: () => this.hasInputTarget ? this.inputTarget : this.element,
            data: data,
            debounce: this.debounceValue,
            resultsList: {maxResults: this.maxResultsValue},
            resultItem: {
                element: source ? source.styleOptionNode : null,
                highlight: true
            },
            events: {
                input: {
                    focus() {
                        const inputValue = autocompletion.input.value;
                        if (inputValue.length) autocompletion.start();
                    },
                    blur: () => autocompletion.close(),
                    open() {
                        const canFitBelow =
                            autocompletion.input.getBoundingClientRect().bottom + autocompletion.list.getBoundingClientRect().height <=
                            (window.innerHeight || document.documentElement.clientHeight);
                        autocompletion.list.classList.toggle('position-above', !canFitBelow);
                    },
                },
            }
        };
        if (this.hasSourceTypeValue || this.hasSourceUrlValue) {
            config.searchEngine = searchEngine
        }

        this.inputTarget.addEventListener("selection", (e) => {
            if (this.hasListGroupOutlet) {
                const selectedOption = e.detail.selection.value,
                    optionId = selectedOption ? (source && source.getOptionId ? source.getOptionId(selectedOption) : selectedOption.id) : null;
                this.listGroupOutlet.addListItem(this.sourceKeyValue ? selectedOption[this.sourceKeyValue] : selectedOption, optionId);
                e.target.value = "";
            } else if (this.sourceKeyValue) {
                e.target.value = e.detail.selection.value[this.sourceKeyValue];
            } else {
                e.target.value = e.detail.selection.value;
            }
        });

        // Importing {autoComplete as AutoComplete} results in an error
        // noinspection JSPotentiallyInvalidConstructorUsage
        autocompletion = new autoComplete(config);
        this.autoComplete = autocompletion;
    }


}
